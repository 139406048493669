<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_contents_header myp">
				<div class="title-txt">일반 프로젝트 &gt; 진행단계별 프로젝트<br/>
					<p class="myp"><span>{{projectCountMap.projectTotalCount}}</span>개의 프로젝트가 있습니다!</p>
				</div>
			</div>
			<div v-if="unEvalCount > 0" class="myp-banner">
				<img src="/images/top_banner.png" alt="상단배너"/>
			</div>
			<div class="mem_info_contents">
				<div class="tab-type2">
					<ul class="tab">
						<li class="on">
							모집중인 프로젝트 
							<!-- <span class="count">{{projectCountMap.projectRecCount}}건</span> -->
						</li>
						<li @click="$router.push({name : 'PRJ302M05'})">
							제안단계 프로젝트
							<!-- <span class="count">{{projectCountMap.projectProCount}}건</span> -->
						</li>
						<li @click="$router.push({name : 'PRJ302M02'})">
							수행예정 프로젝트
							<!-- <span class="count">{{projectCountMap.projectPreCount}}건</span> -->
						</li>
						<li @click="$router.push({name : 'PRJ302M01'})">
							수행중인 프로젝트
							<!-- <span class="count">{{projectCountMap.projectIngCount}}건</span> -->
							<div v-if="projectCountMap.projectIngUnEvalCount > 0" class="alarm show">
								<img src="/images/tab_alarm.png" alt="미평가인재알람"/>
								<p>미평가 인재가 있습니다!</p>
							</div>
						</li>
						<li @click="$router.push({name : 'PRJ302M03'})">
							완료 프로젝트
							<!-- <span class="count">{{projectCountMap.projectEndCount}}건</span> -->
							<div v-if="projectCountMap.projectEndUnEvalCount > 0" class="alarm show">
								<img src="/images/tab_alarm.png" alt="미평가인재알람"/>
								<p>미평가 인재가 있습니다!</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="contents-body prj_mn">
					<div class="search-box">
						<div class="prj_tit">모집중인 프로젝트 <span class="prj_cnt">{{pageInfo.totalRecordCount}}건</span></div>
						<InputComp type="text" placeholder="프로젝트명" v-model="input.searchKeyword" @keyup.enter="getList('1')"  ref="ref_search" />
						<div class="search_btn" @click="getList('1')"><img class="search" src="/images/search_btn.png" alt="검색버튼"/></div>
					</div>
					<div class="filter">
						<div class="filter_box">
							<ul>
								<li><p class="text">NO.</p></li>
								<li><img :class="input.orderGubun == '1' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('1')">등록일 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '5' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('5')">개발기간 긴 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '4' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('4')">개발기간 짧은 순</p><span>ㅣ</span></li>
							</ul>
						</div>
					</div>
					
					<div v-for="(project, index) in projectList" :key="project.projectSeq" class="prj_mn-box">
						<div class="no">
							{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}
						</div>
						<div class="view">
							<div class="req box-hover">
								<div class="top">
									<div v-if="project.projectStatus == 2" class="state_mark ep mr-2-5">수행예정</div>
									<div v-if="project.projectStatus == 4" class="state_mark us mr-2-5">제안단계</div>
									<div v-if="project.projectStatus == 1" class="state_mark pf mr-2-5">수행중</div>
									<div class="proc" v-if="project.recrEndDateDiff >= 0">+모집중</div>
									<div class="new" v-if="project.newYn == 'Y'">NEW</div>
								</div>								
								
								<!--class _new add-->
								<div class="itv-outline int_prj_new">
								
									<div class="regi pb-0">
										<div class="tit int_prj">프로젝트명</div>
										<div class="name prj-tit-hover" style="width: 540px;" @click="clickProjectInfo(project.projectSeq, project.projectDivCd)">{{project.projectNm}}</div>
										<div class="int_box">
											<div class="list-view int_prj">
												<div class="label ivl03">개요</div>
												<div class="list text">{{project.projectDtlDesc}}</div>
											</div>
											<div class="list-view int_prj">
												<div class="label ivl04">고객사</div>
												<div class="list">
													<div class="add">{{project.clientNm}}</div>
													<div class="loca"><img src="/images/location.png" alt="위치" />{{project.projectLocCont}}</div>
												</div>
											</div>
											<div class="list-view int_prj">
												<div class="label ivl05">산업분야</div>
												<div class="list" style="display: inline-block !important;"><SelectComp type="text" cdId="PRO105" :value="project.indtypeClassCd.substr(0,1)"/> / <SelectComp type="text" cdId="PRO143" :value="project.indtypeClassCd"/></div>
											</div>
										</div>
									</div>

									<div class="int_box">                                            
										<div class="list-view int_prj">
											<div class="label ivl04">수행사</div>
											<div class="list text">{{project.corpNm}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">개발기간</div>
											<div class="list">{{project.projectStartEndYyyymmdd}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">직무분야</div>
											<div class="list">
												<ul class="duty-list">
													<li v-for="(duty, index) in project.dutyList" :key="index">
														<span>
															<SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilterCd"/>/<SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyFieldCd"/>
														</span>
													</li>
												</ul>
											</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">개발기술</div>
											<div class="list text">{{project.devTechCont}}</div>
										</div>                                          
									</div>                                        
								</div>

								<!--고객CI/참여인재정보-->
								<div class="client_info absol">
									<div class="right">
										<div class="date">등록일자<nbsp/>{{project.regYyyymmdd}}</div>
									</div>
									<div class="ci_box">
										<ImageComp type="image" :src="'/api/prj/tec/projectCorpImg?projectSeq='+project.projectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
									</div>
									<div class="human_count">
										<div class="count-box"> 
											<span class="count">{{project.appMberCnt}}명</span>
											<div class="sp_human" @click="goAppMber(project.projectSeq)"><span>지원인재 보기 &#9654;</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="projectList.length == 0" class="project_box prj">
						<div class="prj_pf none">
							프로젝트가 없습니다!
						</div>
					</div>
				</div>
				
				<!--페이징 컴포넌트 -->
				<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
				<!--// 페이징 컴포넌트 -->
			</div>
		</div>
	</div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';

export default {

	components : {
		PagingComp
	},

	data() {
		return {
			input: {
				pageIndex : '1',
				
				searchGubun : '1',		// 검색은 프로젝트명으로만
				projectStatus : ['0'],	// (배열로..) 프로젝트 상태 1:수행중, 2:수행예정, 9:완료
				orderGubun : '1',		// 1:등록일 순, 2:참여인재 많은 순, 3:참여인재 적은 순, 4:개발기간 짧은 순, 5:개발기간 긴 순
				// projectGubun : '3',
				projectDivCd : '1',

			}, // 검색조건		

			nowYyyymmdd : '',

			projectList : [],
			projectTecMberList : [],
			pageInfo : {},
			projectCountMap : {},
			unEvalCount : 0,
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');

		if(this.$route.params != undefined && this.$route.params.pageIndex != undefined) {			
			this.input = this.$route.params;
		}
		this.nowYyyymmdd = new Date().format("yyyyMMdd");
		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList(div, isScroll = false) {
			if(div) this.input.pageIndex = 1;
			
			this.$.httpPost('/api/prj/dco/pmg/getMyProjectList', this.input)
				.then(res => {
					//console.log('getMyProjectList RESULT', res.data);

					this.projectList 		= res.data.list;
					this.pageInfo 			= res.data.pageInfo;
					this.projectCountMap	= res.data.projectCountMap;
					this.unEvalCount		= res.data.projectCountMap.projectIngUnEvalCount + res.data.projectCountMap.projectEndUnEvalCount;

					for(var i in this.projectList){
						
						if(this.projectList[i].dutyFieldCont){
							var tempArr = this.projectList[i].dutyFieldCont.split(',');
							this.projectList[i].dutyList = [];

							for(var j in tempArr){
								this.projectList[i].dutyList.push({dutyFilterCd : tempArr[j].substr(0,2),
																   dutyFieldCd  : tempArr[j]});
							}
						}

					}
					if(isScroll) {
						window.scroll(0, 0);
					}
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList('', true);
		},

		orderSearch(orderGubun) {
			this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getList();
		},		

		// 지원인재 보기
		goAppMber(projectSeq) {
            //this.$router.push({name : 'PRJ305M01', query : {projectSeq : projectSeq}, params : { srchName : projectNm, caller : { name : 'PRJ302M02', params :this.input}}});
			this.$router.push({name : 'PRJ305M02', params : { projectSeq : projectSeq, caller : { name : this.$route.name, params :this.input}}});
		},

		clickProjectInfo(projectSeq, projectDivCd) {			
			// this.$router.push({name : 'PRJ303M01', query : {projectSeq : projectSeq}, params : {div : projectDivCd, caller : { name : 'PRJ302M02', params :this.input}}});
			this.$router.push({name : 'PRJ303M01', query : {projectSeq : projectSeq, page : projectDivCd == "32" || projectDivCd == "31" ? 1 : 0}, params : {caller : { name : this.$route.name, params :this.input}}});
		},

	},
};
</script>
